<template>
   <v-card class="mx-auto" elevation="16" min-width="300" max-width="600" :title="dialog.title.toUpperCase()"
      color="cards">
      <v-divider class="mt-3"></v-divider>
      <div class="py-12 text-center">
         <v-icon class="mb-6" :color="dialog.title" :icon="dialog.icon" size="128"></v-icon>
         <div class="text-h4 font-weight-bold mx-16" v-html="dialog.text"></div>
      </div>
      <v-divider></v-divider>
      <div class="pa-4 text-end">
         <v-btn v-if="dialog.redirect != null" class="text-none" :color="dialog.title" min-width="92" :loading="working"
            rounded variant="flat" @click="btnAction">
            Okay
         </v-btn>
      </div>
   </v-card>
</template>

<script>
import bus from "vue3-eventbus";
import router from "@/router";
import { setUpdate } from "@/firebase";

export default {
   props: ["dialog"],
   data: () => ({
      working: false,
   }),
   methods: {
      btnAction() {
         if (this.dialog.redirect == null) {
            //
         } else if (this.dialog.redirect == "Redirect") {
            router.go();
         } else if (this.dialog.redirect == "Close" || this.dialog.redirect == "close") {
            this.$emit('close');
         } else if (this.dialog.redirect == "Update") {
            this.updateApp();
         } else if (this.dialog.redirect == "reloadCards") {
            bus.emit('reloadComponents', "reloadCards");
         } else if (this.dialog.redirect == "reloadStages") {
            bus.emit('reloadComponents', "reloadStages");
         } else if (this.dialog.redirect == "reload") {
            router.go(0);
         } else if (this.dialog.redirect == "back") {
            router.go(-1);
         } else if (this.dialog.redirect == "reload&Close") {
            this.$emit('close');
            bus.emit('reloadView');
         } else {
            router.push(`${this.dialog.redirect}`);
         }

      },
      async updateApp() {
         this.working = true;

         try {
            await setUpdate().then((result) => {
               this.working = false;
               router.go();
            });
         } catch (error) {
            console.error(error);
         }
      },
   }
}
</script>
<style></style>